<template>
    <div class="container py-3">
        <div v-if="alert.message" :class="`alert ${alert.type}`">{{alert.message}}</div>
        <div class="row align-content-between">
            <div class="row">
                <div class="col-md-4"></div>
                <div class="col-md-4">
                    <h3>Login</h3>
                    <form @submit.prevent="handleSubmit">
                        <div class="form-group">
                            <label for="username">Username</label>
                            <input type="text" v-model="username" name="username" class="form-control" :class="{ 'is-invalid': submitted && !username }" />
                            <div v-show="submitted && !username" class="invalid-feedback">Username is required</div>
                        </div>
                        <div class="form-group">
                            <label htmlFor="password">Password</label>
                            <input type="password" v-model="password" name="password" class="form-control" :class="{ 'is-invalid': submitted && !password }" />
                            <div v-show="submitted && !password" class="invalid-feedback">Password is required</div>
                        </div>
                        <div class="form-group py-3">
                            <button class="btn btn-primary" :disabled="status.loggingIn">Login</button>
                            
                            <div v-show="status.loggingIn" class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-md-4"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    export default {
        data() {
            return {
                username: '',
                password: '',
                submitted: false
            }
        },
        computed: {
            ...mapState({
                alert: state => state.alert,
                status: state => state.account.status
            })
        },
        created() {
            // reset login status
            this.logout();
        },
        methods: {
            ...mapActions('account', ['login', 'logout']),
            handleSubmit() {
                this.submitted = true;
                const { username, password } = this;
                if (username && password) {
                    this.login({ username, password })
                }
            }
        }
    };
</script>